<template>
  <Footer :columns="columns" />
</template>

<script>
import Footer from 'chimera/all/themes/blueflow/components/layout/Footer'

export default {
  components: {
    Footer,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      columns: [
        {
          title: 'Chi siamo',
          links: [
            {
              text: 'Contatti',
              to: '/contattaci',
            },
          ],
        },
        {
          title: 'Professionisti',
          links: [
            {
              text: 'Registrati come professionista',
              to: '/contattare-azienda',
            },
          ],
        },
      ],
    }
  },
}
</script>
